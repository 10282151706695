/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixabay.com/photos/hammer-auction-law-justice-crime-7354618/"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "토스의 ", React.createElement(_components.a, {
    href: "https://simplicity-23.toss.im/"
  }, "Simplicity23 웹사이트"), "에 대해"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "네이버 새 홈페이지"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "옛 시대와 현 시대의 브라우저 호환성"), "\n", React.createElement(_components.li, null, "웹 2.0의 유산"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://arc.net"
  }, "Arc 브라우저")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "가장 큰 장점은 스페이스"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "자료 정리 도구에 대한 간단한 소회들"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Obsidian, Devonthink, Roam Research, LogSeq"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.pypi.org/posts/2023-05-24-pypi-was-subpoenaed/"
  }, "PSF, PyPI 사용자에 대한 법무부 소환장을 받다")), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
